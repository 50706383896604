<template>
  <div>
    <v-row dense>
      <v-col cols="12" md="3">
        <search-item
          :searchString="searchString"
          :no-results="true"
          @setSearchString="setSearchString"
          @get-results="getItemResults"
          @clear-input="clearItem"
        ></search-item>
      </v-col>
      <v-col cols="12" md="4"></v-col>
      <v-col cols="12" md="5" class="d-flex justify-end">
        <v-radio-group
          @change="adjustType"
          v-model="adjustmentType"
          column
          dense
          class="ma-0 pa-2"
        >
          <v-radio label="Prijsverhoging €" value="increaseByAmount"></v-radio>
          <v-radio label="Prijsverlaging €" value="decreaseByAmount"></v-radio>
          <v-radio label="Prijsverhoging %" value="increaseByPercentage"></v-radio>
          <v-radio label="Prijsverlaging %" value="decreaseByPercentage"></v-radio>
        </v-radio-group>
        <v-text-field
        ref="adjustPriceBy"
          class="ma-2"
          label="Waarde"
          :value="adjustPriceBy"
          @input="setAdjustedPrice"
          @focus="adjustPriceBy=''"
        ></v-text-field>
        <v-btn :disabled="selectedItems.length==0||adjustPriceBy==0" class="ma-2" color="primary" @click="adjustPrice"
          >Prijs aanpassen</v-btn
        >
      </v-col>
      <v-col>
        <v-btn-toggle class="d-flex flex-wrap" style="background:none;">
          <v-btn color="primary" @click="selectCategory(false)">
            Alles
          </v-btn>
          <v-btn
            color="primary"
            v-for="category of $store.state.settings.categories"
            :key="category"
            @click="selectCategory(category)"
          >
            {{ category }}
          </v-btn>
        </v-btn-toggle></v-col
      >
    </v-row>
    <v-data-table
      v-model="selectedItems"
      :items="items"
      :headers="headers"
      :options.sync="options"
      :server-items-length="itemsLength"
      :footer-props="{
        showFirstLastPage: true,
        'items-per-page-options': [10, 25, 50, -1]
      }"
      class="elevation-1"
      item-key="_id"
      show-select
    >
    <template v-slot:[`item.price`]={item}>
        {{toCurrency(item.price)}}
    </template>
    </v-data-table>
  </div>
</template>
<script>
import { fetchGET, fetchPOST, toCurrency } from "../js/functions"
import searchItem from "../components/searchItem"
export default {
  name: "priceAdjustments",
  data() {
    return {
      headers: [
        { text: "Artikelnr", value: "itemNumber" },
        { text: "Naam", value: "name" },
        { text: "Verkoopprijs", value: "price" }
      ],
      items: [],
      itemsLength: 0,
      selectedItems: [],
      selectedCategory: "",
      showArchived: false,
      options: {
        page: 1,
        itemsPerPage: 25,
        sortBy: ["itemNumber"],
        sortDesc: [false]
      },
      searchString: "",
      adjustmentType: "increaseByAmount",
      adjustPriceBy: 0
    }
  },
  methods: {
      toCurrency(price) {
        return toCurrency(price)
      },
    async fetchItems() {
      let data = {
        selectedCategory: this.selectedCategory,
        showArchived: this.showArchived,
        options: this.options
      }
      let response = await fetchGET("fetchItems", data)
      this.items = response[0].results.length ? response[0].results : []
      this.itemsLength = response[0].length.length
        ? response[0].length[0].length
        : 0
    },
    async adjustPrice() {
      this.adjustType()
      let itemNumbers = this.selectedItems.map(item => item.itemNumber)
      let data = {
        itemNumbers: itemNumbers,
        adjustPriceBy: this.adjustPriceBy
      }
      let event
      if(this.adjustmentType=="increaseByAmount" || this.adjustmentType=="decreaseByAmount") event = "adjustPriceByAmount"
      else event = "adjustPriceByPercentage"
      let res = await fetchPOST(event, data)
      console.log(res)
        this.selectedItems = []
        this.options.page = 1
        this.adjustPriceBy = 0
        this.adjustmentType = "increaseByAmount"
        this.fetchItems()        
    },
    setAdjustedPrice(e) {
        if(e.length>0){
      let string = e.toString().replace(",", ".")
      this.adjustPriceBy = parseFloat(string)
        }
    },
    adjustType() {
      if (this.adjustmentType === "increaseByAmount" && this.adjustPriceBy < 0) {
        this.adjustPriceBy = this.adjustPriceBy * -1
      }
      if (this.adjustmentType === "decreaseByAmount" && this.adjustPriceBy > 0) {
        this.adjustPriceBy = this.adjustPriceBy * -1
      }
      if (this.adjustmentType === "increaseByPercentage" && this.adjustPriceBy < 0) {
        this.adjustPriceBy = this.adjustPriceBy * -1
      }
      if (this.adjustmentType === "decreaseByPercentage" && this.adjustPriceBy > 0) {
        this.adjustPriceBy = this.adjustPriceBy * -1
      }
    },
    getItemResults(items) {
      this.items = items
    },
    setSearchString(val) {
      this.searchString = val
    },
    clearItem() {
      this.options.page = 1
      this.itemNumber = 0
      this.searchString = ""
      this.fetchItems()
    },
    selectCategory(cat) {
      this.searchString = ""
      this.selectedCategory = cat
      this.options.page = 1
      this.showArchived = false
      this.fetchItems()
    },
    selectArchived() {
      this.searchString = ""
      this.selectedCategory = false
      this.options.page = 1
      this.showArchived = true
      this.fetchItems()
    }
  },
  watch: {
    options(newValue, oldValue) {
      let props = ["page", "itemsPerPage", "sortBy", "sortDesc"]
      for (let prop of props) {
        if (oldValue[prop] !== newValue[prop]) return this.fetchItems()
      }
    }
  },
  mounted() {
    this.fetchItems()
  },
  components: {
    searchItem
  }
}
</script>
<style scoped>
</style>